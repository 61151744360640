import {
  createRouter,
  createWebHistory
} from "vue-router";
import Login from "../views/login.vue";
// import {
//   Session
// } from "../utils/storage";
const routes = [{
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/index",
    name: "index",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/index.vue"),

  },
  {
    path: "/Profile",
    name: "Profile",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/Profile.vue"),
  },
  {
    path: "/Additionalofficer",
    name: "Additionalofficer",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/Additionalofficer.vue"),
  },
  {
    path: "/redactitionalofficer",
    name: "redactitionalofficer",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/redactitionalofficer.vue"),
  },
  {
    path: "/Incumbentofficer",
    name: "Incumbentofficer",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/Incumbentofficer.vue"),
  },
  {
    path: "/member",
    name: "member",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/member.vue"),
  },
  {
    path: "/addmember",
    name: "addmember",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/addmember.vue"),
  },
  {
    path: "/hotlist",
    name: "hotlist",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/hotlist.vue"),
  },
  {
    path: "/editactivity",
    name: "editactivity",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/editactivity.vue"),
  },

  {
    path: "/addactivity",
    name: "addactivity",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/addactivity.vue"),
  },
  {
    path: "/annualReport",
    name: "annualReport",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/annualReport.vue"),
  },
  {
    path: "/ChapterRules",
    name: "ChapterRules",
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import("../views/ChapterRules.vue"),

  }
];


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition);
    return {
      top: 0
    };
  },
});

// // 创建全局前置守卫
// router.beforeEach((to, from, next) => {
//   console.log(to, 1233454, from, 1243);
// const isAuthenticated = localStorage.getItem("token");
// if (isAuthenticated) {
//   // 如果需要用户登录权限而用户未登录，重定向到登录页
//   next('/index');
// } else {
//   // 否则，继续导航
//   next('/');
// }
// let dsds = localStorage.getItem("token")
// console.log(dsds, 1234);
// if (to.meta.requiresAuth && localStorage.getItem("token")!='null') {
//   // 如果需要用户登录权限而用户未登录，重定向到登录页
//   next('/');
// } else {
//   // 否则，继续导航
//   next();
// }
// });


export default router;
