<template>
  <div class="main">
    <div class="bk1">
      <img src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/foot%403x.png" alt="">
    </div>
    <div class="bottom">如需帮助，请联系secretariat@iadge.org</div>
  </div>
</template>
    
    <script>
</script>

    <style scoped lang="scss">
.main {
  height: 85px;
  width: 100%;
  display: flex;
  background: linear-gradient(168deg, #631f7a 0%, #d95347 40%, #e3cc30 100%);
  border-radius: 0px 80px 0px 0px;
  align-items: center;
  padding: 0 100px;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .bk1 {
    z-index: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
    // max-width: 100%;
    // max-height: 100%;
    img {
      width: 130%;
    }
  }
  // position: absolute;
  // bottom: 0px;
  .bottom {
    z-index: 1;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 19px;
  }
}
</style>
    