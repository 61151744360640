<template>
  <div id="app">
    <my-header></my-header>
    <router-view />
    <my-footer></my-footer>
  </div>
</template>
<script>
// @ is an alias to /src
import MyHeader from "@/components/MyHeader.vue";
import MyFooter from '@/components/MyFooter.vue'
export default {
  name: "Home",
  components: {
    MyHeader,
    MyFooter
  },
};
</script>
<style lang="scss">
#app{
  // width: 1920px;
}
</style>
