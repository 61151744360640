//登录
import {
  get,
  post,
} from './api'
// 登录
export const login = (data) => post('/chapter_login', data)
// 退出登录
export const loginout = (data) => post('/logout', data)
// 首页数据
export const home = (data) => get('/user/chapter/info', data)
// 分会管理员信息提交数据
export const chapter = (data) => post('/user/chapter/info/submit', data)
// 分会会员列表
export const member = (data) => post('/user/chapter/member_list', data)
// 分会会员详细
export const memberdetail = (data) => post('/user/chapter/member_detail', data)
// 分会会员信息提交数据
export const membersubmit = (data) => post('/user/chapter/member_submit', data)
// 分会会员信息编辑
export const memberedit = (id, data) => post(`/user/chapter/member_edit/${id}`, data)
// 分会活动列表
export const activitylist = (data) => post(`/user/chapter/activity_list`, data)
// 分会活动详细
export const activitydetail = (data) => post(`/user/chapter/activity_detail`, data)
// 分会根据指定年份查询所有活动
export const activity_year = (year, data) => post(`/user/chapter/activity_year/${year}`, data)
// 分会活动信息编辑
export const activity_edit = (id, data) => post(`/user/chapter/activity_edit/${id}`, data)
// 分会活动信息添加
export const activity_add = (data) => post(`/user/chapter/activity_add`, data)
// 分会活动销毁
export const activity_destroy = (id, data) => post(`/user/chapter/activity_destroy/${id}`, data)
//分会年度报告列表
export const report_list = (data) => post('/user/chapter/report_list', data)
//分会年度报告详细
export const report_detail = (data) => post('/user/chapter/report_detail', data)
// 分会年度报告添加/编辑
export const report_write = (year, data) => post(`/user/chapter/report_write/${year}`, data)
