import axios from '@/utils/request'

function get(url, params=[], config=[]) {
    return new Promise((resolve, reject) => {
        let extra = {};
        config && (extra = config)
        if (params) {
            // @ts-ignore
            extra.params = params
        }
        axios
            .get(url, extra)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if (err) {
                    reject(err)
                }
            })
    })
}

function post(url, params=[]) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}



export {
    get,
    post,
}
