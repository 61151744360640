import {
    createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import 'normalize.css/normalize.css'
import store from "./store";
import './index.css'
import './public.css'
import ElementPlus from 'element-plus';
import moment from "moment";
import 'moment/locale/zh-cn'; // 中文（简体）本地化文件
import Ant from "ant-design-vue";
import 'element-plus/dist/index.css'
import "ant-design-vue/dist/reset.css";
// 存储状态到 localStorage
localStorage.setItem('vuexState', JSON.stringify(store.state));
moment.locale('zh-cn'); // 设置默认本地化为中文（简体）
// 在应用启动时从 localStorage 恢复状态
if (localStorage.getItem('vuexState')) {
  const savedState = JSON.parse(localStorage.getItem('vuexState'));
  console.log(savedState,999999999999999);
  store.replaceState(Object.assign({}, store.state, savedState));
}
createApp(App).use(store).use(Ant).use(ElementPlus).use(router).mount("#app");