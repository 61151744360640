import {
  createStore
} from "vuex";

export default createStore({
  state: {
    login: false,
    userinfo: {},
    branch:''
  },
  mutations: {
    updateValue() {
      this.state.login = true;
      // console.log(state.login,newValue, 9999,this.$store.state.login);
    }
  },
  actions: {},
  modules: {},
});
