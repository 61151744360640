<template>
  <div class="home">
    <div class="login">
      <div class="thetitle">登录你的账户</div>
      <div class="account">账号</div>
      <div>
        <el-input
          v-model="lastname.account"
          placeholder="请输入你的账号"
          type="text"
        ></el-input>
      </div>
      <div class="password">密码</div>
      <div class="bottom60">
        <el-input
          v-model="lastname.password"
          placeholder="请输入你的密码"
          type="password"
        ></el-input>
      </div>
      <div
        class="loginsubmit hoverbottom"
        :class="{ click: logins }"
        @click="submit()"
      >
        登录
      </div>
    </div>
  </div>
</template>

<script>
import { Session } from "../utils/storage";
import { login } from "../api/twice";
import { ElNotification } from "element-plus";
export default {
  name: "Home",
  data() {
    return {
      lastname: {
        password: "",
        account: "",
      },
    };
  },
  components: {},
  computed: {
    logins() {
      if (this.lastname.password && this.lastname.account) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async submit() {
      if (!this.logins) return;
      const res = await login({
        username: this.lastname.account,
        password: this.lastname.password,
      });
      res.token && this.loginsuccess(res.token);
    },
    loginsuccess(e) {
      ElNotification({
        title: "Success",
        message: "登录成功!正在为您跳转到首页.",
        type: "success",
      });
      Session.set("token", e);
      // localStorage.setItem("token",e);
      this.$store.commit("updateValue");

      setTimeout(() => {
        this.$router.push("/index");
      }, 300);
    },
  },
};
</script>
<style lang="scss">
.login {
  .el-input__wrapper:hover {
    border: 2px solid #c81677 !important;
  }
  .el-input__wrapper:focus {
    border: 2px solid #c81677 !important;
  }
  .el-input__wrapper {
    height: 74px;
    padding: 0px 48px !important;
    border: 2px solid #777777 !important;
    border-radius: 50px !important;
  }
}
</style>
<style scoped lang="scss">
.home {
  // color: #000;
  max-width: 666px;
  margin: 0 auto;

  padding: 158px 0px;
  .login {
    width: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    .thetitle {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
      text-align: center;
    }
    .account {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      margin-top: 60px;
      margin-bottom: 20px;
      margin-left: 48px;
    }
    .password {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      margin-top: 36px;
      margin-bottom: 20px;
      margin-left: 48px;
    }
    .loginsubmit {
      width: 268px;
      height: 76px;
      background: #ccc;
      border-radius: 100px 100px 100px 100px;
      margin-top: 60px;
      font-size: 18px;
      text-align: center;
      margin: 0 auto;
      font-weight: 400;
      color: #ffffff;
      cursor: no-drop;
      line-height: 76px;
    }
  }
}
</style>
